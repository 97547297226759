import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDfAxY6Z2FVF_0nZyoxPDCGbS62BBOcYeA",
    authDomain: "blog-practice-85078.firebaseapp.com",
    projectId: "blog-practice-85078",
    storageBucket: "blog-practice-85078.firebasestorage.app",
    messagingSenderId: "230748819839",
    appId: "1:230748819839:web:c16a198f4e837bc3a18a4a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//Firestoreを使用するための準備
const db = getFirestore(app);

export { app, db };