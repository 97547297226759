import React, { useEffect, useState } from 'react';
import "./LiquidationCard.css";
import MemberTag from '../MemberTag/MemberTag';

const LiquidationCard = ({ payer, to, amount }) => {
    // 色を決めるための変数
    const [color, setColor] = useState("");

    // レンダーしたときにインデックスから色を決める
    useEffect(() => {
        decideColor(payer.index);
    }, [payer.index])

    // 受け取ったindexによってタグの色を決める
    function decideColor(memberId) {
        // HSLの色相(Hue)をメンバーIDに基づいて決定
        const hue = (memberId * 37) % 360; // 37は適当なプライム数で、色相の変化を確保
        const pastelColor = `hsl(${hue}, 60%, 80%)`; // 彩度60%、輝度80%でパステル調に
        setColor(pastelColor);
    }

    return (
        <div className="liquidationCard" style={{ borderColor: color }}>
            <MemberTag memberName={payer.name} memberId={payer.index} />
            <ion-icon name="arrow-forward-outline"></ion-icon>
            <MemberTag memberName={to.name} memberId={to.index} />
            <div className="amount">{amount}円</div>
        </div>
    )
}

export default LiquidationCard