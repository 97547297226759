import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import EventHome from "./components/EventHome/EventHome";
import AddMember from './components/AddMember/AddMember';
import LiquidationHome from './components/LiquidationHome/LiquidationHome';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/addmember' element={<AddMember />} />
        <Route path='/addmember/:id' element={<AddMember />} />
        <Route path='/eventhome/:id' element={<EventHome />}></Route>
        <Route path='/liquidation/:id' element={<LiquidationHome />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
