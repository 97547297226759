import React, { useEffect, useState } from 'react';
import './LiquidationHome.css';
import { collection, deleteDoc, doc, getDoc, getDocs, setDoc, writeBatch } from 'firebase/firestore';
import { db } from '../../firebase';
import { useParams } from 'react-router-dom';
import LiquidationCard from '../LiquidationCard/LiquidationCard';

const LiquidationHome = () => {
    const { id } = useParams(); // パラメータからidを取得
    const [balances, setBalances] = useState(null);
    const [members, setMembers] = useState([]);
    const [liquidationsInfo, setLiquidationsInfo] = useState([]); // 最終的な清算情報を保持するstate

    useEffect(() => {
        async function fetchData() {
            const eventData = await fetchEventData(id);
            if (eventData) {
                const fetchedMembers = eventData.members || [];
                setMembers(fetchedMembers);

                const payments = await fetchPayments(id);
                await saveLiquidations(fetchedMembers, payments, id);
                await saveLiquidationsInfo(fetchedMembers, id,);
                await fetchLiquidationsInfo(id); // 最終的な清算情報を取得
            }
        }
        fetchData();
    }, [id]);

    // データベースからイベントデータを取得する
    async function fetchEventData(eventId) {
        const eventsRef = doc(db, "events", eventId);
        const docSnap = await getDoc(eventsRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            console.error("メンバー情報が見つかりませんでした");
            return null;
        }
    }

    // データベースから支払情報を取得する
    async function fetchPayments(eventId) {
        const paymentsRef = collection(db, "events", eventId, "payments");
        const querySnapshot = await getDocs(paymentsRef);

        const payments = [];
        querySnapshot.forEach((doc) => {
            payments.push({ id: doc.id, ...doc.data() });
        });
        return payments;
    }

    // liquidationsInfoを作成するためのliquidationsをデータベースに保存する関数
    async function saveLiquidations(members, payments, eventId) {
        // データベースへのパス
        const liquidationsRef = collection(db, "events", eventId, "liquidations");

        // 清算情報を配列に格納する関数
        function addOrUppdateLiquidation(myLiquidations, to, amount) {
            // 清算相手の情報がすでに存在するかを確認
            const existing = myLiquidations.find((item) => item.to.name === to.name);

            if (existing) {
                console.log("加算されていない？");
                // 対象が存在する場合、amountを加算する
                existing.amount += amount;
            } else {
                // 存在しない場合は新しく格納する
                myLiquidations.push({ to: to, amount: amount });
            }
        }

        // バッチ処理
        const batch = writeBatch(db);
        // ひとりずつの清算情報を合計していく
        for (const main of members) {
            // 自分の清算情報を保存する配列
            const myLiquidations = [];
            // すべての支払い情報を確認
            for (const payment of payments) {
                // 支払者が自分の場合はスキップ
                if (payment.payer.name !== main.name) {
                    // 支払情報のmembersの中に自分が存在した場合
                    if (payment.members.some((member) => member.name === main.name)) {
                        // 清算相手への金額を参照
                        // 金額÷関係者数
                        let amount = Math.round(payment.amount / payment.members.length);
                        // 自分の清算情報として格納
                        addOrUppdateLiquidation(myLiquidations, payment.payer, amount);
                    }

                    // ドキュメントへのパス
                    const liqRef = doc(liquidationsRef, `${main.name}_${payment.payer.name}`);

                    // 清算情報の数だけループ
                    myLiquidations.forEach((liquidation) => {
                        console.log(liquidation.amount);
                        // データを保存
                        batch.set(liqRef, {
                            amount: liquidation.amount,
                            payer: { name: main.name, index: main.index },
                            to: { name: liquidation.to.name, index: liquidation.to.index },
                        });
                    })
                }
            }
        }
        await batch.commit();
    }

    async function saveLiquidationsInfo(members, eventId) {
        const liquidationInfoRef = collection(db, "events", eventId, "liquidationInfo");
        const liquidationsRef = collection(db, "events", eventId, "liquidations");

        // バッチ処理
        const batch = writeBatch(db);

        // liquidationInfo コレクション内のすべてのドキュメントを削除
        const liquidationInfoSnap = await getDocs(liquidationInfoRef);

        for (const doc of liquidationInfoSnap.docs) {
            await deleteDoc(doc.ref);
        }

        // 支払情報を取得
        const liquidationsSnap = await getDocs(liquidationsRef);
        const liquidations = [];
        liquidationsSnap.forEach((doc) => {
            liquidations.push({ id: doc.id, ...doc.data() });
        });

        // 新しい清算情報を保存
        // メンバー分のループ処理
        for (const main of members) {
            // すべての清算情報を確認
            for (const liq of liquidations) {
                let payFlag = false;
                // 自分が支払うべき清算情報でなければスキップ
                if (main.name !== liq.payer.name) {
                    console.log("関係ないので飛ばします")
                    continue;
                }

                // 相手に対しての金額を保持
                const ownLiquidation = liq.amount;
                // 特定の相手から自分に向けた清算があるかどうかを確認
                for (const subliq of liquidations) {
                    console.log(liq.payer.name, "⇒", subliq.payer.name);
                    // 清算の支払う側と支払われる側が一致しているとき
                    if (liq.payer.name === subliq.to.name && liq.to.name === subliq.payer.name) {
                        payFlag = true;
                        // 相手から自分への清算金額を保持
                        const oppoLiquidation = subliq.amount;
                        // 清算金額を比べて
                        if (ownLiquidation === oppoLiquidation) {
                            console.log("金額が一緒なので飛ばします。")
                            continue;
                        } else if (ownLiquidation > oppoLiquidation) {
                            // 自分の方が大きければ差額を自分が支払う
                            const payer = { name: main.name, index: main.index };
                            const to = { name: liq.to.name, index: liq.to.index };
                            const gap = ownLiquidation - oppoLiquidation;
                            const liqInfoRef = doc(liquidationInfoRef, `${payer.name}_${to.name}`);

                            // 新しい清算情報を保存
                            batch.set(liqInfoRef, {
                                payer,
                                to,
                                amount: gap,
                                done: false, // 初期状態では未完了
                            }, { merge: true });
                            console.log("自分が支払う方が多いので自分が支払います。", payer.name, "⇒", to.name, gap);
                        } else if (ownLiquidation < oppoLiquidation) {
                            // 相手の方が大きければ差額を相手が支払う
                            const to = { name: main.name, index: main.index };
                            const payer = { name: liq.to.name, index: liq.to.index };
                            const gap = oppoLiquidation - ownLiquidation;
                            const liqInfoRef = doc(liquidationInfoRef, `${payer.name}_${to.name}`);

                            // 新しい清算情報を保存
                            batch.set(liqInfoRef, {
                                payer,
                                to,
                                amount: gap,
                                done: false, // 初期状態では未完了
                            }, { merge: true });
                            console.log("相手の支払いが多いので相手が支払います。", payer.name, "⇒", to.name, gap);
                        }

                    }
                };

                // 双方向の清算が無かったら保存する。あれば保存しない。
                if (!payFlag) {
                    const payer = { name: main.name, index: main.index };
                    const to = { name: liq.to.name, index: liq.to.index };
                    const liqInfoRef = doc(liquidationInfoRef, `${payer.name}_${to.name}`);
                    batch.set(liqInfoRef, {
                        payer,
                        to,
                        amount: liq.amount,
                        done: false,
                    }, { merge: true });
                    console.log("清算に対抗がないのでデータを追加するだけです。", payer.name, "⇒", to.name, liq.amount);
                }
            }
        }

        // バッチ処理をコミットして保存
        await batch.commit();
    }


    // 最終清算情報をFirestoreから取得する
    async function fetchLiquidationsInfo(eventId) {
        const liquidationsRef = collection(db, "events", eventId, "liquidationInfo");
        const querySnapshot = await getDocs(liquidationsRef);

        const liquidationsData = [];
        querySnapshot.forEach((doc) => {
            liquidationsData.push({ id: doc.id, ...doc.data() });
        });
        setLiquidationsInfo(liquidationsData);
    }

    return (
        <div className='liquidation-container'>
            <h1>清算状況</h1>
            {liquidationsInfo ? (
                <>
                    {liquidationsInfo.length > 0 ? (
                        liquidationsInfo.map((liq) => (
                            <LiquidationCard key={liq.id} payer={liq.payer} to={liq.to} amount={liq.amount} />
                        ))
                    ) : (
                        <p>清算情報を読み込んでいます...</p>
                    )}
                </>
            ) : (
                <p>データを読み込んでいます...</p>
            )}
        </div>
    );
};

export default LiquidationHome;
