import React from 'react';
import MemberTag from '../MemberTag/MemberTag';
import './PaymentCard.css';

const PaymentCard = ({ payer, amount, members, detail }) => {
    return (
        <div className="payment-item">
            <div className="payment-title">
                <div className="payer">{payer.name}</div>
                <div className="amount">
                    <p>{amount}円</p>
                </div>
                <div className="detail">
                    <p>{detail}</p>
                </div>
            </div>
            <div className="members">
                {
                    members.map((member) => (
                        <MemberTag key={member.name} memberName={member.name} memberId={member.index} />
                    ))
                }
            </div>
        </div>
    );
};

export default PaymentCard;
