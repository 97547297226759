import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AddPayment from '../AddPayment/AddPayment';
import { addDoc, collection, doc, getDoc, getDocs, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import './EventHome.css';
import PaymentCard from '../PaymentCard/PaymentCard';
import MemberTag from '../MemberTag/MemberTag';

const EventHome = () => {
    //パラメータからidを取得する
    const { id } = useParams();
    //navigateを使用する準備
    const navigate = useNavigate();
    //モーダルの表示を保存する
    const [showModal, setShowModal] = useState(false);
    //参加メンバーを表示するために保存
    const [members, setMembers] = useState([]);
    //paymentに保存するメンバーを保存
    const [selectedMembers, setSelectedMembers] = useState([]); // 初期値は空の配列
    //支払ったメンバーを保存
    const [payer, setPayer] = useState([]);
    //支払った金額を保存
    const [amount, setAmount] = useState("");
    //支払った内容を保存
    const [detail, setDetail] = useState("");
    //支払データを取得して保存
    const [paymentsData, setPaymentsData] = useState([]); // 支払いデータを保存する状態
    // イベント名を保存
    const [eventName, setEventName] = useState("");

    //読み込み時に一度だけ、idが変更されたときに呼び出される
    useEffect(() => {
        //イベントデータを取得する
        fetchEventData();
        //支払データを取得する
        fetchPaymentsData();
    }, [id]);

    //イベントデータを取得する関数
    const fetchEventData = async () => {
        //eventsのデータを取得
        const docRef = doc(db, "events", id);
        //docSnapに格納
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const eventData = docSnap.data();
            setMembers(eventData.members || []); //参加メンバーの配列を取得

            // イベント名を格納
            setEventName(eventData.name);

            // 全員を選択状態にする
            const allMemberNames = eventData.members.map(member => ({ name: member.name, index: member.index }));
            setSelectedMembers(allMemberNames); // オブジェクトの配列をセット

        } else {
            console.log("データがありませんでした。");
        }
    };

    //支払データを取得する関数
    const fetchPaymentsData = async () => {
        const paymentsRef = collection(db, "events", id, "payments");  // サブコレクション「payments」の参照を取得
        const snapshot = await getDocs(paymentsRef);  // サブコレクション内の全ドキュメントを取得

        if (!snapshot.empty) {
            const paymentsList = [];
            snapshot.forEach(doc => {
                const paymentsData = doc.data();  // ドキュメントのデータを取得
                paymentsList.push(paymentsData); // 配列に追加
            });

            //支払データの一覧保存
            setPaymentsData(paymentsList);
        } else {
            console.log("支払いデータがありません");
        }
    };

    const handleChange = (e) => {
        const selectedPayer = members.find(member => member.name === e.target.value);
        setPayer(selectedPayer);
    };

    const handleAmount = (e) => {
        setAmount(Number(e.target.value));
    };

    const handleDetail = (e) => {
        setDetail(e.target.value);
    }

    //清算画面に遷移する
    const navigateLiquidation = () => {
        navigate(`/liquidation/${id}`);
    }
    //クリックしたメンバーの選択状態を切り替えするイベントハンドラ
    const toggleMemberSelection = (memberName, memberIndex) => {
        setSelectedMembers((prevSelected) => {
            if (prevSelected.some(member => member.name === memberName)) {
                // クリックしたメンバーを選択から除外
                return prevSelected.filter(member => member.name !== memberName);
            } else {
                // 新しいメンバーをオブジェクト形式で追加
                return [...prevSelected, { name: memberName, index: memberIndex }];
            }
        });
    };

    //支払データを保存する関数
    const savePaymentData = async () => {
        try {
            console.log("Selected Members:", selectedMembers); // この時点でオブジェクトの配列

            const eventRef = collection(db, 'events', id, "payments");
            await addDoc(eventRef, {
                payer: payer,
                members: selectedMembers, // そのままオブジェクト配列として保存
                amount: amount,
                detail: detail,
                timestamp: serverTimestamp(),
            });
            console.log("選択されたデータが保存されました。");

            // フォームのリセット
            setPayer([]);
            setSelectedMembers([]); // 保存後にリセットする
            setAmount("");
            setDetail("");
            closeModal();

            // 更新したデータを再度取得する
            fetchPaymentsData();
        } catch (error) {
            console.error("データの保存に失敗しました:", error);
        }
    };


    const navigateToAddMember = () => {
        navigate(`/addmember/${id}`);
    };

    //モーダルを開く関数
    const openModal = () => {
        setShowModal(true);

        // モーダルを開くたびに全員選択する
        setSelectedMembers(members.map(member => ({ name: member.name, index: member.index })));

        if (members.length > 0) {
            setPayer(members[0]); // 最初のメンバーをデフォルトで選択
        }
    };

    //モーダルを閉じる関数
    const closeModal = () => {
        setShowModal(false);
    };


    return (
        <div className='event-home'>
            <button onClick={navigateToAddMember}>メンバーを追加</button>
            <div className="title-container">
                <div className="event">event</div>
                <div className="event-name">
                    {eventName}
                </div>
            </div>
            <AddPayment openModal={openModal} />

            {/* 支払いデータを表示 */}
            <div className="payments-list">
                {paymentsData.map((payment, index) => (
                    <PaymentCard
                        key={index}
                        payer={payment.payer}
                        amount={payment.amount}
                        members={payment.members}
                        detail={payment.detail}
                    />
                ))}
            </div>

            <div className="liquidation-button">
                <button onClick={navigateLiquidation}>清算</button>
            </div>

            {/* モーダルで支払情報を入力 */}
            {showModal && (
                <>
                    <div className="overlay" onClick={closeModal}></div>

                    {/* モーダル */}
                    <div className="modal">
                        {/* 支払者を選択 */}
                        <div className="input-payer">
                            <label htmlFor="dropdown">支払者:</label>
                            <select id="dropdown" value={payer.name} onChange={handleChange}>
                                {members.map((member) => (
                                    <option key={member.index} value={member.name}>{member.name}</option>
                                ))}
                            </select>
                        </div>

                        {/* 関係のあるメンバーを選択 */}
                        <div className="input-member">
                            {members.map(member => (
                                <div
                                    key={member.name}
                                    className={`member-tag ${selectedMembers.some(selected => selected.name === member.name) ? 'selected' : 'unselected'}`}
                                    onClick={() => toggleMemberSelection(member.name, member.index)}
                                >
                                    <MemberTag memberName={member.name} memberId={member.index} />
                                </div>
                            ))}
                        </div>
                        {/* 金額を入力 */}
                        <div className="input-amount">
                            <label htmlFor="amount">金額:</label>
                            <input id="amount" type="number" value={amount} onChange={handleAmount} placeholder='xxxx円' />
                        </div>
                        {/* 支払った内容を記入 */}
                        <div className="input-detail">
                            <label htmlFor='detail'>内容:</label>
                            <input type="text" value={detail} onChange={handleDetail} placeholder='何に使った？' />
                        </div>
                        {/* データをFirestoreに保存 */}
                        <div className="save-button">
                            <button onClick={savePaymentData}>選択したデータを保存</button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default EventHome;
