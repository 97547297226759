import React from 'react';
import './Navbar.css';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    //navigateを使用する準備
    const navigate = useNavigate();

    // クリックでホームへ戻るイベントハンドラ
    const handleClick = () => {
        navigate("/");
    }

    return (
        <header>
            <div className="logo" onClick={handleClick}>
                <h1>未定</h1>
            </div>
        </header>
    )
}

export default Navbar