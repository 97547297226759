import React from 'react'
import MemberTag from '../MemberTag/MemberTag'
import './EventCard.css'
import { useNavigate } from 'react-router-dom'

const EventCard = ({ name, members, eventId }) => {

    // クリックしたイベントのidを使用してイベントホームに遷移する
    const handleNavigate = () => {
        navigate(`eventhome/${eventId}`);
    }

    const navigate = useNavigate();
    return (
        <div className="event-card" onClick={handleNavigate}>
            <div className="event-name">{name}</div>
            <div className="members">
                {
                    members.map((member) => (
                        <MemberTag key={member.index} memberName={member.name} memberId={member.index} />
                    ))
                }
            </div>
        </div>
    )
}

export default EventCard