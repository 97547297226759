import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddEvent from "../AddEvent/AddEvent";
import EventCard from "../EventCard/EventCard";
import { v4 as uuidv4 } from 'uuid';
import "./Home.css";
import { db } from "../../firebase";
import { collection, doc, getDocs, query, where } from "firebase/firestore";

function Home() {
    const [showModal, setShowModal] = useState(false);
    const [eventName, setEventName] = useState("");
    const [eventsData, setEventsData] = useState([]);
    const navigate = useNavigate();

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const handleNext = () => {
        console.log("遷移するイベント名:", eventName);
        navigate("/addmember", { state: { eventName } });
        closeModal();
    };

    // 端末のuuidを取得する関数
    function getOrGenerateUUID() {
        // ローカルストレージに UUID が保存されているか確認
        let deviceUUID = localStorage.getItem('deviceUUID');

        // 保存されていなければ新しい UUID を生成して保存
        if (!deviceUUID) {
            deviceUUID = uuidv4();
            localStorage.setItem('deviceUUID', deviceUUID);
        }

        return deviceUUID;
    }



    useEffect(() => {

        fetchEventsData();
    }, []);

    // uuidをキーにしてデータベースからデータを取得する関数
    async function fetchEventsData() {
        // uuidを取得
        const deviceUUID = getOrGenerateUUID();
        // uuidが一致するイベントデータを取得
        const eventsRef = collection(db, "events");
        const q = query(eventsRef, where("uuid", "==", deviceUUID));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {

            const eventsList = [];
            querySnapshot.forEach(doc => {
                const eventsData = doc.data();
                eventsList.push({ id: doc.id, ...eventsData });
            })
            setEventsData(eventsList);

        }
    };

    return (
        <div className="app">
            <AddEvent openModal={openModal} />

            {/* EventCardをmap関数でイベントの数だけ表示する */}
            {
                eventsData.map((event) => (

                    <EventCard key={event.id} name={event.name} members={event.members} eventId={event.id} />
                ))
            }

            {showModal && (
                <>
                    {/* 薄暗くするオーバーレイ */}
                    <div className="overlay" onClick={closeModal}></div>

                    {/* モーダル本体 */}
                    <div className="modal">
                        <button onClick={closeModal} className="close-button">×</button>
                        <div className="event-title">イベント名を入力してください</div>
                        <input
                            type="text"
                            placeholder="イベント名"
                            value={eventName}
                            onChange={(e) => setEventName(e.target.value)}
                        />
                        <div className="link-to-next">
                            {/* onClickでhandleNext関数を呼び出して遷移 */}
                            <button onClick={handleNext}>次へ</button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Home;
