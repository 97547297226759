import React, { useEffect, useState } from 'react';
import './MemberTag.css';




const MemberTag = ({ memberName, memberId }) => {
    // タグの色を保存するState
    const [color, setColor] = useState("");

    // レンダーしたときにインデックスから色を決める
    useEffect(() => {
        decideColor(memberId);
    }, [memberId])

    // 受け取ったindexによってタグの色を決める
    function decideColor(memberId) {
        // HSLの色相(Hue)をメンバーIDに基づいて決定
        const hue = (memberId * 37) % 360; // 37は適当なプライム数で、色相の変化を確保
        const pastelColor = `hsl(${hue}, 60%, 80%)`; // 彩度60%、輝度80%でパステル調に
        setColor(pastelColor);
    }


    return (
        <div className="member-tag" style={{ backgroundColor: color }}>
            <div className='member-name'>{memberName}</div>
        </div >
    )
}

export default MemberTag