import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, addDoc, collection, serverTimestamp, getDoc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import MemberTag from '../MemberTag/MemberTag';
import './AddMember.css';

const AddMember = () => {
    //URLからパラメータを取得してidに代入
    const { id } = useParams();

    //navigateで渡されたstateを受け取る処理
    const location = useLocation();
    const { eventName } = location.state || {};

    //navigateを使用する準備
    const navigate = useNavigate();

    //stateの準備
    const [eName, setEName] = useState(eventName);
    const [name, setName] = useState("");
    const [members, setMembers] = useState([]);


    //AddMemberコンポーネントにアクセスしたときにパラメータを持っている場合
    useEffect(() => {
        //eventsからデータを取得する
        const fetchEventData = async () => {
            if (id) {
                //URLにパラメータがあるときは既存のイベントデータがあるということ
                //eventsからデータを取得
                const docRef = doc(db, "events", id);
                const docSnap = await getDoc(docRef);

                //指定のデータがnullでない場合
                if (docSnap.exists()) {
                    const eventData = docSnap.data();
                    setEName(eventData.name);
                    setMembers(eventData.members || []);
                } else {
                    console.log("データがありませんでした。");
                }
            } else {
                console.log("イベントはありません");
            }
        }
        fetchEventData();
    }, [])

    function getOrGenerateUUID() {
        // ローカルストレージに UUID が保存されているか確認
        let deviceUUID = localStorage.getItem('deviceUUID');

        // 保存されていなければ新しい UUID を生成して保存
        if (!deviceUUID) {
            deviceUUID = uuidv4();
            localStorage.setItem('deviceUUID', deviceUUID);
        }

        return deviceUUID;
    }

    //データベースにデータを追加する
    async function onAddData() {
        const deviceUUID = getOrGenerateUUID(); // UUID を取得
        try {
            if (id) {
                //イベントIDが存在する場合は新規ではなく追加になる
                const docRef = doc(db, "events", id);
                await getDoc(docRef);

                //メンバーを更新
                await setDoc(docRef, {
                    members: members,
                }, { merge: true });

                navigate(`/eventhome/${id}`);
            } else {

                //eventsコレクションにデータを追加する
                const docRef = await addDoc(collection(db, "events"), {
                    name: eventName,
                    members: members,
                    uuid: deviceUUID,
                    timestamp: serverTimestamp()
                });

                //membersをクリアする
                setMembers([]);

                //データを追加したら次のページへ遷移する
                navigate(`/eventhome/${docRef.id}`);
            }
        } catch (e) {
            //データが追加できなかったときの処理
            console.error("メンバーの追加に失敗しました", e);
        }
    }

    //入力欄に記入した値をStateに保存する
    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    //メンバーを追加する
    const handleAddMember = () => {
        //nameに保存されたメンバーをindexをつけてmembersに保存する
        const maxIndex = members.length > 0 ? Math.max(...members.map(member => member.index)) : 0;
        setMembers([
            ...members,
            { name: name, index: maxIndex + 1 },
        ]);

        //nameの値をリセットする
        //nameの値が入力フォームに反映されているので、nameをクリアすることで入力フォームをクリアする
        setName("");
    }

    return (
        <div className='add-member'>
            <div className="title-container">
                <div className="event">event</div>
                <div className="event-name">
                    {eName}
                </div>
            </div>
            <div className="member-list">
                {
                    members.map((member) => (
                        <MemberTag key={member.index} memberName={member.name} memberId={member.index} />
                    ))
                }
            </div>

            <div className="input-member">
                <div className="input-title">
                    メンバーを入力してください
                </div>
                <div className="input-group">
                    <input
                        type="text"
                        value={name}
                        onChange={handleNameChange}
                        placeholder="名前"
                    />
                    <button onClick={handleAddMember}>追加</button>
                </div>
            </div>
            <button onClick={onAddData}>決定</button>
        </div>

    )

}

export default AddMember